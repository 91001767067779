<template>
    <div class="pdf-view">
        <iframe :src="fullUrl"  />
    </div>
</template>
<script>
    export default {
        name: 'View',
        data: () =>({
           fullUrl: ''
        }),
        mounted() {
            this.fullUrl = this.$route.query.doc
        }
    }
</script>
